/* eslint-disable no-undef */
<template>
  <div>
    <v-dialog v-model="promptpayDialog" max-width="400px"
      ><template>
        <v-container>
          <v-card
            ><v-card-text
            ><img :src="qrCode"/>
            <span>{{promptpayResult}}</span>
              </v-card-text>
            <v-card-footer>
              <v-btn @click="promptpayDialog = !promptpayDialog" >Close</v-btn></v-card-footer
            >
          </v-card>
        </v-container>
      </template>
    </v-dialog>


    <v-card>
      <v-card-title> Treatment Proposal Checkout For </v-card-title>
      <v-card-text>
        <template>
          <table class="paymentTable">
            <thead>
              <tr>
                <th>Item Description</th>
                <th>Cart Amount ({{ currency }})</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ description }}</td>
                <td>
                  <table class="paymentDetail">
                    <tbody>
                      <tr>
                        <td class="text-right">Price</td>
                        <td class="text-right">{{ price }}</td>
                      </tr>
                      <tr>
                        <td class="text-right">Discount code</td>
                        <td class="text-right">{{ discount }}%</td>
                      </tr>
                      <tr>
                        <td class="text-right">Grand Total</td>
                        <td class="text-right">{{ price - price * (discount / 100) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title> Record/Collection & Shipping Information </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row></v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <v-container ref="form" id="form" name="form">
          <v-row>
            <v-col md="12" sm="12">
              <table class="pay">
                <thead>
                  <tr>
                    <th>Choose Payment</th>
                    <th>Amount ({{ currency }})</th>
                    <!-- <th></th> -->
                    <th>Make Payment</th>
                  </tr>
                </thead>
                <tbody>
                  <!--tr>
                    <td>Credit Account:</td>
                    <td>{{ price - price * (discount / 100) }}</td>
                    <td><v-btn @click="savePayment" block tile>Use Credit</v-btn></td>
                  </!--tr-->
                  <tr>
                    <td>Pay for lab fee only</td>
                    <td>{{ price - price * (discount / 100) }}</td>
                     <td>
                      <v-select
                        v-model="payment_type"
                        label="Payment Type"
                        :items="payment_selection"
                        :item-text="text"
                        :item-value="value"
                      >
                      </v-select>
                    </td> 
                    <td>
                      <v-btn
                        block
                        tile
                        color="primary"
                        :disabled="!agree_terms_payment"
                        id="checkout-btn"
                        @click="callOmise"
                        >Pay now</v-btn
                      >
                    </td>
                  </tr>
                  <!--tr>
                    <td>Pay via Points for lab fee only</td>
                    <td>{{ price - price * (discount / 100) }}</td>
                    <td><v-btn @click="savePayment" block tile>Use Points</v-btn></td>
                  </!--tr-->
                  <tr>
                    <td>Treatment Proposal:</td>
                    <td colspan="2">
                      <input
                        type="checkbox"
                        id="term"
                        v-model="agree_terms_payment"
                        value="Yes"
                      />I agree to the <a href="#" @click="showTerm">Disclaimer </a> and
                      want to receive treatment proposal for this patient.
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      max-width="680px"
    >
      <v-card>
        <v-card-title> ข้อจำกัดความรับผิดชอบ </v-card-title>
        <v-card-text>
          <div class="term">
            <p>
              นโยบายความเป็นส่วนตัวของเว็บไซต์บริษัท เคเซอร์ อไลเนอร์ จำกัด
              ตระหนักถึงความสำคัญในการปกป้องความเป็นส่วนตัวของคุณ เคเซอร์ อไลเนอร์
              จึงขอให้คำมั่นในการเก็บรักษาและสงวนข้อมูลที่คุณมอบให้เพื่อนำมาใช้บนเว็บไซต์นี้ด้วยนโยบายความเป็นส่วนตัวที่ปรากฏ
              ณ ที่นี้
            </p>
            <ul>
              <li>
                ในการเข้าถึงเว็บไซต์ของบริษัทฯ คุณยินยอมให้บริษัทฯ
                สามารถเก็บบันทึกข้อมูลของคุณได้ภายใต้เงื่อนไขที่ปรากฏในนโยบายความเป็นส่วนตัวนี้
              </li>
              <li>
                ข้อมูลส่วนตัวของคุณที่มอบให้แก่เว็บไซต์ของบริษัทฯ
                จะถูกเก็บรักษาไว้เป็นความลับอย่างเข้มงวดตลอดเวลาเพื่อความเป็นส่วนตัวของคุณ
              </li>
              <li>
                บริษัทฯ เก็บรวบรวมข้อมูล
                เพื่อนำมาใช้ในการพัฒนาและปรับปรุงการให้บริการที่ดีขึ้นแก่คุณ
                โดยเริ่มตั้งแต่ข้อมูลพื้นฐาน อย่างเช่น ชื่อ ตลอดจนข้อมูลที่มีความซับซ้อน
                เช่น โฆษณาใดที่จะเป็นประโยชน์แก่คุณมากที่สุด
                หรือคอร์สเรียนใดที่คุณอาจจะชื่นชอบ เป็นต้น
              </li>
              <li>
                เราเก็บรักษาข้อมูลส่วนบุคคลเพื่อให้เป็นไปตามหน้าที่ตามกฎหมายและเพื่อวัตถุประสงค์ทางธุรกิจของเรา
                เราอาจเก็บรักษาข้อมูลส่วนบุคคลไว้เป็นระยะเวลานานกว่าที่กฎหมายกำหนดถ้าอยู่ในความต้องการทางธุรกิจที่ถูกกฎหมายของเราและไม่ต้องห้ามตามกฎหมาย
                ถ้าบัญชีของคุณถูกปิด
                เราอาจดำเนินการตามขั้นตอนเพื่อปกปิดข้อมูลส่วนบุคคลและข้อมูลอื่นๆ
                แต่เราขอสงวนสิทธิ์ในการเก็บรักษาและเข้าถึงข้อมูลตราบใดที่จำเป็นในการปฏิบัติตามกฎหมายที่บังคับใช้
                เราจะยังคงใช้และเปิดเผย “ข้อมูลส่วนบุคคล” ดังกล่าวตาม
                “นโยบายความเป็นส่วนตัว” นี้
              </li>
              <li>
                คุณสามารถเข้าถึงข้อมูลส่วนบุคคลของคุณได้
                หากข้อมูลดังกล่าวมีความบกพร่องหรือไม่ถูกต้อง บริษัทจะพยายามจัดหาวิธีการ
                และ/หรือช่องทางให้คุณสามารถอัปเดตและ/หรือลบข้อมูลส่วนบุคคลของคุณได้อย่างเหมาะสม
              </li>
              <li>
                การเข้าถึงข้อมูลส่วนตัวจะถูกจำกัดแต่เฉพาะพนักงานของ เคเซอร์ อไลเนอร์ จำกัด
                ที่ได้รับอนุญาตและได้รับการฝึกฝนให้ดูแลจัดการข้อมูลเท่านั้น
                บุคคลเหล่านี้จะเก็บรักษาข้อมูลของคุณไว้เป็นความลับรวมทั้งเคารพความเป็นส่วนตัวของคุณตลอดเวลา
              </li>
              <li>
                บริษัทฯ
                อาจส่งอีเมล์หรือจดหมายข่าวถึงคุณเป็นครั้งคราวเพื่อแจ้งข้อมูลล่าสุดของเว็บไซต์
                ผลิตภัณฑ์ บริการ โปรโมชัน ฯลฯ
                หากคุณไม่ประสงค์รับอีเมล์หรือจดหมายข่าวเหล่านี้
                คุณสามารถบอกเลิกการติดต่อดังกล่าวโดยไปยังลิงค์หรือปฏิบัติตามขั้นตอนที่ปรากฏ
              </li>
              <li>
                บริษัทฯ
                พร้อมดูแลรักษาข้อมูลส่วนตัวของคุณจากการเข้าถึงข้อมูลโดยไม่ได้รับอนุญาต
                โดยบริษัทฯ
                ได้ตั้งระบบและมาตรการรักษาความปลอดภัยเพื่อให้ข้อมูลอยู่ในสถานะที่ปลอดภัยและมั่นคง
              </li>
              <li>
                บริษัทฯ ทราบดีว่า การเก็บข้อมูลของคุณให้ถูกต้อง ครบถ้วนสมบูรณ์
                และทันสมัยอยู่เสมอเป็นสิ่งสำคัญ
                คุณสามารถช่วยเพิ่มความถูกต้องของข้อมูลได้โดยการแจ้งการเปลี่ยนแปลงของข้อมูลใดๆ
                ให้บริษัทฯ ทราบอย่างสม่ำเสมอ หากคุณมีข้อสงสัยว่าข้อมูลของคุณที่บริษัทฯ
                ไม่ถูกต้องหรือไม่ครบถ้วนสมบูรณ์ ขอให้แจ้งให้บริษัทฯ ทราบ ซึ่งบริษัทฯ
                จะดำเนินการแก้ไขข้อผิดพลาดดังกล่าว
              </li>
              <li>
                บริษัทฯ พร้อมรับฟังความคิดเห็น
                ข้อข้องใจและข้อร้องเรียนที่เกี่ยวข้องกับข้อมูลของคุณหรือความเป็นส่วนตัวของข้อมูล
                กรุณาติดต่อบริษัทฯ ผ่านทางไปรษณีย์หรืออีเมล์ บริษัทฯ
                จะดำเนินการตอบข้อซักถามและแก้ปัญหาที่เกิดขึ้นโดยทันที
              </li>
            </ul>
            <p>
              ข้อกำหนดและเงื่อนไขของ Kase Aligner สำหรับการอนุมัติแผนการรักษาบน web-base
              ของ Kase Aligner
            </p>
            <ul>
              <li>
                1. Kase Aligner
                เป็นห้องปฏิบัติการทางทันตกรรมซึ่งผลิตผลิตภัณฑ์ตามใบสั่งทันตแพทย์ที่ได้รับอนุมัติจากทันตแพทย์
              </li>
              <li>
                2. ผลิตภัณฑ์ที่ผลิตโดย Kase Aligner คือ เครื่องมือจัดฟันแบบใส
                ซึ่งเป็นเครื่องมือที่แพทย์ใช้ในการกดทับฟันเพื่อการจัดฟัน
              </li>
              <li>
                3. Kase Aligner นำเสนอแบบจำลอง 3 มิติและข้อเสนอการรักษาในรูปแบบ PDF
                เพื่อให้ทันตแพทย์อนุมัติหรือให้ข้อเสนอแนะหากมีการปรับเปลี่ยนใด ๆ
                เมื่อทันตแพทย์อนุมัติแล้ว
                จะถือว่าเป็นใบสั่งที่ทันตแพทย์สั่งเพื่อสร้างอุปกรณ์จัดฟัน
              </li>
              <li>
                4. ผลลัพธ์ทางคลินิกขั้นสุดท้ายอาจให้ผลลัพธ์ที่แตกต่างจากการจำลอง 3
                มิติดั้งเดิม เนื่องจากผลการรักษาขึ้นอยู่กับปัจจัยต่างๆ เช่น
                ความแตกต่างทางชีวภาพการปฏิบัติตามคำแนะนำและอื่น ๆ
                ทันตแพทย์จำเป็นต้องทำการตรวจสอบในการรักษาแต่ละขั้นตอนและแก้ไขหากจำเป็น
              </li>
              <li>
                5. ทันตแพทย์ไม่มีภาระผูกพันในการรับคำแนะนำ ข้อคิดเห็น ระเบียบวิธีใด ๆ ที่
                Kase Aligner
                จัดเตรียมไว้ในส่วนที่เกี่ยวข้องกับการรักษาผู้ป่วยทันตแพทย์ควรตัดสินใจตามวิจารณญาณของผู้เชี่ยวชาญสำหรับกระบวนการวางแผน
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-title> Disclaimers </v-card-title>
        <v-card-text>
          <div class="term">
            <p>
              Access to personal data will be restricted to Kase Aligner Ltd. employees
              who are authorized and trained to manage it. These individuals will keep
              your information confidential and respect your privacy at all times.
            </p>
            <ul>
              <li>
                The Company may send you e-mails or newsletters notifying you of the
                latest information on our website, products, services, promotions, etc. If
                you do not wish to receive these e-mails or newsletters, you can opt out
                of such correspondence by following the link or follow the steps shown.
              </li>
              <li>
                The Company may send you e-mails or newsletters notifying you of the
                latest information on our website, products, services, promotions, etc. If
                you do not wish to receive these e-mails or newsletters, you can opt out
                of such correspondence by following the link or follow the steps shown.
              </li>
              <li>
                The Company will protect your personal information from unauthorized
                access. The Company has set up systems and security measures to keep the
                information in a safe and secure state.
              </li>
              <li>
                The Company understands that it is important to keep your information
                accurate, complete, and up-to-date. You can help improve the accuracy of
                the information by regularly notifying the Company of any changes to the
                information. If there is any doubt that your information at the Company is
                inaccurate or incomplete, please notify the Company and the Company will
                correct such errors.
              </li>
              <li>
                The company is open to listening to your opinions and complaints related
                to your information or privacy. Please contact the company via post or
                email, the company will respond to inquiries and solve problems arising
                immediately.
              </li>
            </ul>

            <p>
              Kase Aligner Term and Conditions for Treatment Plan Approval on Kase Aligner
              Aligner web-base application
            </p>
            <ul>
              <li>
                1. Kase Aligner is a dental laboratory fabricating products as per the
                prescription approved by the doctor
              </li>
              <li>
                2. The products fabricated by Kase Aligner is the clear aligner , which is
                a tool used by doctor to apply pressure on teeth in order for alignment
                correction
              </li>
              <li>
                3. Kase Aligner provides 3D Simulation & treatment proposal in PDF format
                for doctor to approve or feedback if there are any modification required.
                Once the doctor approved, it is considered a prescription prescribed by
                the doctor to fabricate the aligners
              </li>
              <li>
                4. Final clinical results may provide different results from the original
                3D simulation. As the results of the treatment can depend on various
                factors such as biological differences, compliances and etc. Doctor
                require to do clinical monitoring on each step and make interception if
                necessary.
              </li>
              <li>
                5. Doctor is under no obligation to adopt any advice, comments, protocols
                provided by Kase Aligner in relation to the treatment to patients. Doctor
                should decide based on own professional judgement for the planning
                process.
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions
          ><p>&nbsp;<v-btn btn @click="dialog = !dialog" color="primary">Close</v-btn></p>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { depositCurrency } from "../../config/config";
import axios from "axios";
export default {
  components: {},
  name: "Payment",
  props: {
    doctor: {
      type: Object,
    },
    caseInfo: {
      type: Object,
    },
    OmiseCard: {
      type: Object,
    },
    Omise: {
      type: Object,
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_LOYALTY_API_URI,
      intervalID: null,
      dialogPromptpay: false,
      promptpayResult: "",
      payment_type: "credit_card",
      payment_selection: [
        { value: "credit_card", text: "Credit Card" },
        { value: "promptpay", text: "Promptpay" },
      ],
      caseNo: this.$route.params.caseNo,
      sequence: this.$route.params.sequence || 0,
      description: "",
      eForm: {
        impressionIsUsedforPatient: "",
      },
      //payment_type:"Credit Card",
      dialog: false,
      promptpayDialog: false,
      qrCode: "",
      agree_terms_payment: "No",
      discount: 0,
      // deposit: Math.ceil(3000 / localStorage.getItem("exchange_rate")),
      // price: Math.ceil(3000 / localStorage.getItem("exchange_rate")),
      deposit: depositCurrency[localStorage.getItem("CURRENCY")],
      price: depositCurrency[localStorage.getItem("CURRENCY")],
      amount: 0,
      vip: "",
      influencer: 0,
      total: 0,
      is_production: process.env.VUE_APP_ENV,
      division: 1,
      paymentType: "",
      //omiseInstant:{},
      omisePublicKey: process.env.VUE_APP_OMISE_PUBLIC_KEY,
      omiseResponse: {},
      // agreeText:
      //  "I agree to the terms & conditions and want to receive treatment proposal for this patient.",

      //actionForm: "/doctor/check-payment/" + this.$route.params.caseNo,
    };
  },
  mounted() {
    this.init();
    //console.log(this.sequence);
    //console.log(this.omisePublicKey);
    //console.log("mounted payment");
    //console.log(this.OmiseCard);
    // Configuring your own custom button
    // eslint-disable-next-line no-undef
    // OmiseCard.configureButton("#checkout-btn", {
    //   frameLabel: "Merchant",
    //   submitLabel: "PAY RIGHT NOW !",
    // });

    // Then, attach all of the config and initiate it by 'OmiseCard.attacsh();' method
    // eslint-disable-next-line no-undef
    //OmiseCard.attach();
    console.log("mounted payment");
    //console.log(this.caseInfo);
    //console.log(this.doctor);

    /*let dr = this.doctor.doctor[0];

    this.price = 3000 / this.division;
    let vip = "";
    if (typeof dr.vip !== "undefined") {
      vip = dr.vip;
    }
    if (typeof dr.discount !== "undefined") {
      this.discount = dr.discount;
    }
    */
    // let vip = 0;
    // if (vip == 1) {
    //   this.discount = 0;
    // }
  },
  computed: {
    currency() {
      return localStorage.getItem("CURRENCY");
    },
  },
  methods: {
    showTerm(e) {
      e.preventDefault();
      console.log(e);
      console.log("showTerm");
      this.dialog = true;
    },
    async init() {
      console.log(this.doctor.doctor[0]);
      this.division = this.is_production == "production" ? 1 : 100;

      let info = await this.caseInfo;
      this.caseNo = this.caseNo || info.caseNo;
      console.log(info);
      this.influencer =
        typeof this.doctor.doctor[0].influencer !== "undefined"
          ? this.doctor.doctor[0].influencer
          : 0;
      console.log("influencer");
      console.log(this.influencer);
      let caseStatus = { stateStatus: "Draft" };
      if (typeof info.caseStatus === "undefined") {
        info.caseStatus = caseStatus;
      }
      if (info.caseStatus.stateStatus != "Draft") {
        //
        this.description = info.package.text;
        if (this.currency == "USD") {
          this.price =
            this.influencer == 1
              ? 0
              : (info.package.us_price - (info.sequence == 3 ? 0 : this.deposit)) /
                this.division;
        } else {
          this.price =
            this.influencer == 1
              ? 0
              : (info.package.price - (info.sequence == 3 ? 0 : this.deposit)) /
                this.division;
        }

        this.paymentType = info.package.value;
      } else if (info.caseStatus.stateStatus == "Draft") {
        console.log(this.deposit);
        this.description = "Treatment Proposal Fee";
        this.price = (this.influencer == 1 ? 0 : this.deposit) / this.division;
        this.paymentType = this.description;
        console.log(this.price);
      }
      this.vip = this.doctor.doctor[0].vip;
      if (this.vip == 1) {
        this.discount = 0;
      } else {
        this.discount = this.doctor.doctor[0].discount || 0;
      }
      this.amount = this.price - (this.price * (this.discount / 100));
      console.log(this.amount);
    },
    savePayment() {
      this.amount = this.price - (this.price * (this.discount / 100));
      console.log("save payment");
      let payload = {
        paymentType: this.paymentType + " ( Case : " + this.caseNo + "  ) ",
        payment_type: this.payment_type,
        amount: this.amount,
        description: this.description,
        discount: this.discount,
        price: this.price,
        caseNo: this.caseNo,
        omise: this.omiseResponse,
      };
      //if(this.payment_type == "credit_card"){
          this.$emit("savePayment", payload); // demo
      //}else{
      //  if(!this.promptpayDialog){
      //    this.$emit("savePayment", payload); // demo
      //    }
      //}
      //this.promptpayDialog =false;
    },
    openOmise() {
      console.log("open omise function");
      console.log(this.amount);
      // eslint-disable-next-line no-undef
      OmiseCard.configure({
        publicKey: this.omisePublicKey,
        image: "https://cdn.omise.co/assets/dashboard/images/omise-logo.png",
        amount:
          this.amount.toString().indexOf(".") > -1
            ? this.amount.toString().replace(".", "") + "0"
            : this.amount.toString() + "00",
        //submitFormTarget: "#form",
        frameDescription: this.description + " ( Case : " + this.caseNo + " )",
        // onCreateTokenSuccess: (nonce) => {
        //   console.log(nonce);
        //   //if (typeof nonce.id !== "undefined") {
        //   //
        //   this.omiseResponse = nonce;
        //   if (nonce.id.startWith("tokn_")) {
        //     this.savePayment();
        //   } else {
        //     alert("Payment error!");
        //   }
        //   //}
        // },
      });
      if (this.payment_type == "promptpay") {
                
        console.log("Promptpay");
        // eslint-disable-next-line no-undef
        Omise.setPublicKey(this.omisePublicKey);
        // eslint-disable-next-line no-undef
        Omise.createSource(
          "promptpay",
          {
            amount: Math.round(this.amount *100),
            currency: this.currency,
          },
          async (statusCode, response) => {
            console.log(statusCode);
            console.log(response);
            console.log(response.id);
            // eslint-disable-next-line no-undef
            //OmiseCharge.create({});
            if(statusCode == 200 && response.id !== undefined){
              // call charge from api
              console.log("Call Charge Promptpay");
              let payload = {
                  amount: Math.round(this.amount *100),
                  currency: this.currency,
                  source_id: response.id,
                  caseNo: this.caseNo,
                }
              console.log(payload);
              console.log(process.env.VUE_APP_LOYALTY_API_URI);
              console.log("/api/bfc/v1/omise/promptpay" );
              
              axios
              .post(
                process.env.VUE_APP_LOYALTY_API_URI + "/api/bfc/v1/omise/promptpay",
                payload, // need to change
                {
                  headers: {
                    // Overwrite Axios's automatically set Content-Type
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
                //let 
                console.log(response);
                if(response.data.data.source.scannable_code.image.download_uri !== undefined){
                this.promptpayDialog = true;

                this.qrCode = response.data.data.source.scannable_code.image.download_uri;
                //console.log(this.qrCode);
                this.omiseResponse = response.data.data;
                //this.payment_type = this.payment_type;
                //this.savePayment();
                console.log(this.omiseResponse);
                console.log("-----------------------------------");
                
                this.intervalID = setInterval((param1)=>{
                    
                  
                    console.log('check payment status');
                    axios
                    .post(
                      process.env.VUE_APP_LOYALTY_API_URI + "/api/bfc/v1/omise/webhook-promptpay",
                      {
                        id: param1.id
                      }, // need to change
                      {
                        headers: {
                          // Overwrite Axios's automatically set Content-Type
                          "Content-Type": "application/json",
                        },
                      }
                    ).then( responseStatus =>{
                      
                         if(responseStatus.data.data.paymentStatus =="Paid"){
                            this.promptpayResult = 'Successful';
                            this.promptpayDialog = false;
                            this.$router.push("/doctor/payment-status/" + this.caseNo);
                         }else{
                            this.promptpayResult = 'Checking payment...';
                            }
                    });
                  },5000,this.omiseResponse);
                }
              
                console.log(this);
                
              }).catch(error=>{
                  console.error(error);
                });
              
              
             }
          }
        );
      } else {
        // eslint-disable-next-line no-undef
        OmiseCard.open({
          //amount: 12345,
          currency: this.currency,
          defaultPaymentMethod: "credit_card",
          onCreateTokenSuccess: (nonce) => {
            console.log(nonce);
            this.omiseResponse = nonce;
            if (nonce.startsWith("tokn_")) {
              //form.omiseToken.value = nonce;
              this.savePayment();
            } else {
              //form.omiseSource.value = nonce;
              alert("Payment error!");
            }
            // form.submit();
          },
          onFormClose: (close) => {
            console.log(close);
            console.log(" omise close() ");
          },
        });
      }
    },

    callOmise() {
      //console.log(e);
      //e.preventDefault();
      console.log(this.vip);
      if (this.vip != 1 && this.influencer != 1) {
        console.log("open omise");
        //this.payment_type = this.payment_selection;
        this.openOmise();
      } else {
        
        this.savePayment();
      }

      // eslint-disable-next-line no-undef
      // OmiseCard.createToken("card", this.card, (statusCode, response) => {
      //   if (response.object == "error" || !response.card.security_code_check) {
      //     console.log(response);
      //     if (response.object == "error") {
      //       console.log(response.message);
      //     }
      //   } else {
      //     console.log(response);
      //     this.token = response.id;
      //   }
      // });
    },
  },
  watch: {
    agree_terms_payment: {
      handler(val) {
        console.log(val);
      },
    },
    promptpayDialog(val,old){

          console.log("promptpayDialog: "+val+"  : "+old);
            if(old){
                if(!val){
                    clearInterval(this.intervalID);
                    this.intervalID = null;
                    this.savePayment();
                  }
              }

      }
  },
};
</script>
<style scope>
.paymentTable {
  width: 100%;
  padding: 0;
  margin: 0;
}
.paymentTable thead td {
  border-bottom: 1px solid #393939;
}
.paymentDetail {
  width: 100%;
  padding: 0;
  margin: 0;
}

.paymentDetail td:nth-child(2) {
  width: 180px !important;
}

.pay {
  width: 100%;
}
.pay tr td:nth-child(1) {
  text-align: right;
}

.term {
  width: 100%;
  background-color: #ffffff;
}
.term p,
.term li {
  font-size: 16px;
  text-align: left;
  max-width: 90%;
}
</style>
