<template>
  <!----->
  <div>
    <teeth-attachment
      v-bind:show="showAttachment"
      v-bind:teethNo="teethNo"
      v-bind:attachmentData="attachmentData"
      v-if="showAttachment"
      @closeAttachment="closeAttachment"
      @selectAttachmentTeeth="selectAttachmentTeeth"
    ></teeth-attachment>
    <v-dialog v-model="uploading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Uploading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <bfc-bar></bfc-bar>
    <doctor-menu></doctor-menu>

    <div style="height: 20px"></div>
    <v-app-bar color="gray" style="height: 48px">
      <v-breadcrumbs :items="breadcrumbs" style="margin-top: -16px">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div style="height: 20px"></div>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <template v-for="n in nSteps">
          <v-stepper-step :key="`${n}-step`" :complete="e1 > n" :step="n">
            Step {{ n }}
          </v-stepper-step>

          <v-divider v-if="n !== nSteps" :key="n"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content key="1-content" step="1">
          <v-card class="mb-12" color="lighten-1">
            <v-card-text>
              <!-- doctor/clinic info -->
              <v-card>
                <v-card-title>Doctor & Clinic Name</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.doctor"
                          :rules="[(v) => !!v || 'This field is required']"
                          label="Doctor Name"
                          required
                          id="doctorName"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.clinicName"
                          label="Clinic Name"
                          :rules="[(v) => !!v || 'This field is required']"
                          required
                          id="clinicName"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>

              <!-- patient info -->
              <div style="height: 20px"></div>
              <v-card>
                <v-card-title>Patient Infomation</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.firstname"
                          :rules="[(v) => !!v || 'This field is required']"
                          label="Firstname"
                          required
                          id="firstname"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.lastname"
                          :rules="[(v) => !!v || 'This field is required']"
                          label="Lastname"
                          required
                          id="lastname"
                        ></v-text-field>
                      </v-col>
                      <!--v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.mobile"
                          label="Mobile"
                          :rules="[
                            (v) =>
                              /(^0)(6|8|9)[0-9]{8,8}/.test(v) || 'Mobile must be valid',
                          ]"
                          required
                          id="mobile"
                        ></v-text-field>
                      </!--v-col-->
                    </v-row>
                    <!--v-row>
                      <v-col md="6" sm="12">
                        <v-menu
                          ref="birthdate"
                          v-model="birthdate"
                          :close-on-content-click="false"
                          :return-value.sync="eForm.birthdate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="eForm.birthdate"
                              label="Start Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              required="required"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="eForm.birthdate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="birthdate = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.birthdate.save(eForm.birthdate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="eForm.race"
                          label="Race"
                          id="race"
                        ></v-text-field>
                      </v-col>
                    </!--v-row-->
                    <v-row>
                      <v-col md="6" sm="12">
                        <p class="text-left">Gender</p>
                        <v-radio-group v-model="eForm.gender" mandatory>
                          <v-radio label="Male" value="male"></v-radio>
                          <v-radio label="Female" value="female"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>

              <!-- patient info -->
              <div style="height: 20px"></div>
              <v-card>
                <v-card-title>Case Infomation</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="eForm.chief_complaint"
                          label="Chief Complaint"
                          clearable
                          clear-icon="mdi-close-circle"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="eForm.treatment_goal"
                          label="Treatment Goal"
                          clearable
                          clear-icon="mdi-close-circle"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-container>
                    <v-row>
                      <v-col sm="12" md="12" class="text-left">
                        Treat Which Arch(s)? (Check 'UPPER & LOWER' for both arches)
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="12" md="12" class="text-left">
                        <v-container fluid>
                          <v-checkbox
                            v-model="eForm.TreatWhichArch"
                            label="UPPER Only"
                            value="UPPER_ONLY"
                          ></v-checkbox>

                          Case Type:
                          <v-radio-group
                            v-model="eForm.upper_only_data"
                            :disabled="eForm.TreatWhichArch != 'UPPER_ONLY'"
                          >
                            <v-radio
                              label="Non-Extraction"
                              value="Non-Extraction"
                            ></v-radio>
                            <v-radio label="Extraction" value="Extraction"></v-radio>
<v-radio label="Starter" value="Starter"></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col sm="12" md="12" class="text-left">
                        <v-container fluid>
                          <v-checkbox
                            v-model="eForm.TreatWhichArch"
                            label="LOWER Only"
                            value="LOWER_ONLY"
                          ></v-checkbox>
                          Case Type:

                          <v-radio-group
                            v-model="eForm.lower_only_data"
                            :disabled="eForm.TreatWhichArch != 'LOWER_ONLY'"
                          >
                            <v-radio
                              label="Non-Extraction"
                              value="Non-Extraction"
                            ></v-radio>
                            <v-radio label="Extraction" value="Extraction"></v-radio>
<v-radio label="Starter" value="Starter"></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>
                      <v-col sm="12" md="12" class="text-left">
                        <v-container fluid>
                          <v-checkbox
                            v-model="eForm.TreatWhichArch"
                            label="UPPER & LOWER"
                            value="UPPER_LOWER"
                          ></v-checkbox>
                          Case Type:

                          <v-radio-group
                            v-model="eForm.upper_lower_data"
                            :disabled="eForm.TreatWhichArch != 'UPPER_LOWER'"
                          >
                            <v-radio
                              label="Non-Extraction"
                              value="Non-Extraction"
                            ></v-radio>
                            <v-radio label="Extraction" value="Extraction"></v-radio>
<v-radio label="Starter" value="Starter"></v-radio>
                          </v-radio-group>
                        </v-container>
                      </v-col>

                      <v-col sm="12" md="12" class="text-left">
                        <v-container fluid>
                          Teeth To Extract:
                          <v-radio-group v-model="eForm.teeth2Extract">
                            <v-radio label="Please select" value="Select"></v-radio>
                          </v-radio-group>

                          <div style="height: 20px">
                            <!-- image -->
                            <div>
                              <table class="teeth2extract">
                                <tr>
                                  <td v-for="n in 8" :key="n">
                                    {{ 19 - n }}
                                  </td>
                                  <!--/tr>
                              <tr class="bfc_td_teeth2extract" style="margin-left: 100px"-->
                                  <td v-for="n in 8" :key="n">
                                    {{ 20 + n }}
                                  </td>
                                </tr>

                                <tr>
                                  <td v-for="n in 8" :key="n">
                                    <input
                                      type="checkbox"
                                      :value="19 - n"
                                      v-model="eForm.teeth2extract_cbx"
                                    />
                                  </td>
                                  <!--/tr>
                                <tr
                                  class="bfc_td_teeth2extract"
                                  style="margin-left: 100px"
                                -->
                                  <td v-for="n in 8" :key="n">
                                    <input
                                      type="checkbox"
                                      :value="20 + n"
                                      v-model="eForm.teeth2extract_cbx"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td class="text-center" colspan="16">
                                    <div
                                      style="
                                        display: flex !important;
                                        justify-content: center !important;
                                      "
                                    >
                                      <img
                                        src="../../assets/teeth-2-extract/UpperTeeth.png"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="16">
                                    <div style="display: flex; justify-content: center">
                                      <img
                                        src="../../assets/teeth-2-extract/LowerTeeth.png"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td v-for="n in 8" :key="n">
                                    {{ 49 - n }}
                                  </td>
                                  <td v-for="n in 8" :key="n">
                                    {{ 30 + n }}
                                  </td>
                                </tr>

                                <tr>
                                  <td v-for="n in 8" :key="n">
                                    <input
                                      type="checkbox"
                                      :value="49 - n"
                                      v-model="eForm.teeth2extract_cbx"
                                    />
                                  </td>
                                  <td v-for="n in 8" :key="n">
                                    <input
                                      type="checkbox"
                                      :value="30 + n"
                                      v-model="eForm.teeth2extract_cbx"
                                    />
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div style="height: 480px"></div>
                          <v-radio-group v-model="eForm.teeth2Extract">
                            <v-radio
                              label="Not sure, please propose"
                              value="PleasePropose"
                            ></v-radio>
                          </v-radio-group>
                        </v-container>
                        <v-divider></v-divider>
                        <v-container>
                          <div v-for="item in caseFormDental" :key="item.key">
                            {{ item.subject }}
                            <div v-for="val in item.values" :key="val.key">
                              <v-checkbox
                                :value="val.text"
                                :label="val.text"
                                v-model="eForm[item.key]"
                              >
                              </v-checkbox>
                              <v-text-field
                                v-model="eForm.caseFormDental[item.key][val.key]"
                                placeholder="e.g 11, 12, 17, 18"
                                v-if="
                                  eForm[item.key].findIndex((elem) => elem == val.text) !=
                                  -1
                                "
                              ></v-text-field>
                              findIndex
                              {{ item.key }} {{ val.key }}
                            </div>
                          </div>
                        </v-container>
                        <v-divider></v-divider>
                        <v-container>
                          <div>
                            Work Only On ( Tick All if required )
                            <v-checkbox
                              v-for="item in caseStructure.workOnlyOn"
                              :key="item"
                              :value="item"
                              :label="item"
                            ></v-checkbox>
                          </div>
                        </v-container>

                        <v-divider></v-divider>
                        <v-container>
                          <div>
                            To Correct Midline
                            <v-radio-group v-model="eForm.ToCollectMidline" row>
                              <v-radio
                                v-for="item in caseStructure.toCollectMidline.radio"
                                :key="item"
                                :label="item"
                                :value="item"
                                @click="toCollectMidlineHandle"
                              ></v-radio>
                            </v-radio-group>
                            <div
                              v-for="item in caseStructure.toCollectMidline.childs"
                              :key="item.key"
                            >
                              {{ item.title }}
                              <v-radio-group
                                v-model="eForm[item.key]"
                                :disabled="eForm.ToCollectMidline == 'No'"
                              >
                                <v-radio
                                  v-for="idx in item.radio"
                                  :key="idx"
                                  :value="idx"
                                  :label="idx"
                                ></v-radio>
                              </v-radio-group>
                            </div>
                          </div>
                        </v-container>

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div v-for="item in caseStructure.radioBoxForm" :key="item.key">
                            {{ item.title }}
                            <div>
                              <v-radio-group v-model="eForm[item.key]" row>
                                <v-radio
                                  v-for="val in item.values"
                                  :key="val"
                                  :label="val"
                                  :value="val"
                                ></v-radio>
                              </v-radio-group>
                            </div>
                          </div>
                        </v-container>

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div
                            v-for="item in caseStructure.ToCorrectSpacing"
                            :key="item.key"
                          >
                            {{ item.title }}
                            <v-radio-group v-model="eForm[item.key].value" row>
                              <v-radio
                                v-for="val in item.values"
                                :key="val"
                                :label="val"
                                :value="val"
                              ></v-radio>
                            </v-radio-group>
                            <div v-for="child in item.childs" :key="child.key">
                              {{ child.title }}

                              <v-radio-group v-model="eForm[item.key][child.key]" column>
                                <v-radio
                                  v-for="(val, index) in child.values"
                                  :key="index"
                                  :label="val"
                                  :value="val"
                                  :disabled="eForm.ToCorrectSpacing.value == 'No'"
                                ></v-radio>
                              </v-radio-group>

                              <!--v-text-field--
                                v-model="eForm.ToCorrectSpacing.TypeofSpaceClosure_other"
                                label="Special Instructions/Notes On Correct Spacing (if any)"
                                id="TypeofSpaceClosure_other"
                                readonly
                              ></!--v-text-field-->
                              <v-text-field
                                v-model="eForm.ToCorrectSpacing.TypeofSpaceClosure_other"
                                label="Special Instructions/Notes On Correct Spacing (if any)"
                                id="TypeofSpaceClosure_other"
                                :readonly="eForm.ToCorrectSpacing.value == 'No'"
                              ></v-text-field>
                            </div>
                          </div>
                        </v-container>

                        <!---------------->

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div
                            v-for="item in caseStructure.ToCorrectCrowding"
                            :key="item.key"
                          >
                            {{ item.title }}
                            <v-radio-group v-model="eForm[item.key].value" row>
                              <v-radio
                                v-for="val in item.values"
                                :key="val"
                                :label="val"
                                :value="val"
                              ></v-radio>
                            </v-radio-group>
                            <div v-for="child in item.childs" :key="child.key">
                              {{ child.title }}

                              <v-checkbox
                                v-for="(val, index) in child.values"
                                v-model="eForm[item.key][child.key]"
                                :key="index"
                                :label="val"
                                :value="val"
                                :disabled="eForm.ToCorrectCrowding.value == 'No'"
                              ></v-checkbox>

                              <!--v-text-field
                                v-model="eForm.ToCorrectSpacing.TypeofSpaceClosure_other"
                                label="Special Instructions/Notes On Correct Spacing (if any)"
                                id="TypeofSpaceClosure_other"
                                readonly
                              ></!--v-text-field-->
                              <v-text-field
                                v-model="eForm.ToCorrectCrowding.RelieveCrowdingBy_other"
                                label="Special Instructions/Notes On Correct Spacing (if any)"
                                id="RelieveCrowdingBy_other"
                                :readonly="eForm.ToCorrectCrowding.value == 'No'"
                              ></v-text-field>
                            </div>

                            <v-container fluid>
                              Relieve Crowding By Stripping:
                              <v-radio-group
                                v-model="
                                  eForm.ToCorrectCrowding.RelieveCrowdingByStripping
                                "
                              >
                                <v-radio label="Please select" value="Select"></v-radio>
                              </v-radio-group>

                              <div style="height: 20px">
                                <!-- image -->
                                <div>
                                  <div>
                                    <ul class="bfc_li_RelieveCrowdingByStripping">
                                      <li
                                        v-for="n in 11"
                                        :key="n"
                                        class="bfc_li_RelieveCrowdingByStripping_cbx padding22"
                                      >
                                        <input
                                          type="checkbox"
                                          :value="19 - n"
                                          v-model="
                                            eForm.ToCorrectCrowding
                                              .bfc_li_RelieveCrowdingByStripping_cbx
                                          "
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="text-left" style="margin-left: 120px">
                                    <img
                                      src="../../assets/correct-crowding/all_teeth2.png"
                                    />
                                  </div>

                                  <div>
                                    <ul
                                      class="bfc_li_RelieveCrowdingByStripping mandible"
                                    >
                                      <li
                                        v-for="n in 11"
                                        :key="n"
                                        class="bfc_li_RelieveCrowdingByStripping_cbx padding18"
                                      >
                                        <input
                                          type="checkbox"
                                          :value="49 - n"
                                          v-model="
                                            eForm.ToCorrectCrowding
                                              .bfc_li_RelieveCrowdingByStripping_cbx
                                          "
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div style="height: 240px"></div>
                              <v-radio-group
                                v-model="
                                  eForm.ToCorrectCrowding.RelieveCrowdingByStripping
                                "
                              >
                                <v-radio
                                  label="Not sure, please propose"
                                  value="PleasePropose"
                                ></v-radio>
                              </v-radio-group>
                            </v-container>
                          </div>
                        </v-container>

                        <!---------------->

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div v-for="item in caseStructure.ModifyTorque" :key="item.key">
                            {{ item.title }}
                            <div>
                              <v-radio-group v-model="eForm[item.key].value" row>
                                <v-radio
                                  v-for="val in item.values"
                                  :key="val"
                                  :label="val"
                                  :value="val"
                                  @click="ModifyTorqueHandle"
                                ></v-radio>
                              </v-radio-group>

                              <div v-for="child in item.childs" :key="child.key">
                                <v-text-field
                                  v-model="eForm.ModifyTorque.WhichTeethandAmount"
                                  :label="child.title"
                                  :id="child.key"
                                  :readonly="eForm[item.key].value != 'Yes'"
                                ></v-text-field>
                              </div>
                            </div>
                          </div>
                        </v-container>

                        <!--------------->

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div
                            v-for="item in caseStructure.ApplyRotation"
                            :key="item.key"
                          >
                            {{ item.title }}
                            <div>
                              <v-radio-group v-model="eForm[item.key].value" row>
                                <v-radio
                                  v-for="val in item.values"
                                  :key="val"
                                  :label="val"
                                  :value="val"
                                  @click="ApplyRotationHandle"
                                ></v-radio>
                              </v-radio-group>

                              <div v-for="child in item.childs" :key="child.key">
                                <v-text-field
                                  v-model="eForm.ApplyRotation.WhichTeethandAmount"
                                  :label="child.title"
                                  :id="child.key"
                                  :readonly="eForm[item.key].value != 'Yes'"
                                ></v-text-field>
                              </div>
                            </div>
                          </div>
                        </v-container>

                        <!--------------->
                        <v-divider></v-divider>
                        <v-container>
                          <div>
                            Attachment:
                            <v-radio-group v-model="eForm.attachment_selected" row>
                              <v-radio key="Yes" label="Yes" value="Yes"></v-radio>
                              <v-radio key="No" label="No" value="No"></v-radio>
                            </v-radio-group>
                            <v-radio-group
                              v-model="eForm.attachment"
                              :disabled="eForm.attachment_selected == 'No'"
                            >
                              <v-radio
                                label="Not sure, please propose"
                                value="PleasePropose"
                              ></v-radio>
                              <v-radio label="Please select" value="Select"></v-radio>
                            </v-radio-group>
                          </div>
                          <div>
                            <v-container>
                              <v-row>
                                <v-col sm="12" cols="12" md="12" class="attachment"
                                  ><ul v-for="m in 8" :key="19 - m">
                                    <!--li>{{ 19 - m }}</!--li-->
                                    <li @click="selectAttachment(19 - m)">
                                      <img
                                        :src="
                                          displayToothImage(
                                            'teeth/teeth-' + (19 - m) + '.png',
                                            19 - m
                                          )
                                        "
                                        :alt="'image teeth-' + (19 - m) + '.png'"
                                      />
                                    </li>
                                  </ul>
                                  <ul v-for="m in 8" :key="m + 20">
                                    <!--li>{{ 19 - m }}</!--li-->
                                    <li @click="selectAttachment(20 + m)">
                                      <img
                                        :src="
                                          displayToothImage(
                                            'teeth/teeth-' + (20 + m) + '.png',
                                            20 + m
                                          )
                                        "
                                        :alt="'image teeth-' + (20 + m) + '.png'"
                                      />
                                    </li>
                                  </ul>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col sm="12" cols="12" md="12" class="attachment"
                                  ><ul v-for="m in 8" :key="49 - m">
                                    <!--li>{{ 19 - m }}</!--li-->
                                    <li @click="selectAttachment(49 - m)">
                                      <img
                                        :src="
                                          displayToothImage(
                                            'teeth/teeth-' + (49 - m) + '.png',
                                            49 - m
                                          )
                                        "
                                        :alt="'image teeth-' + (49 - m) + '.png'"
                                      />
                                    </li>
                                  </ul>
                                  <ul v-for="m in 8" :key="30 + m">
                                    <!--li>{{ 19 - m }}</!--li-->
                                    <li @click="selectAttachment(30 + m)">
                                      <img
                                        :src="
                                          displayToothImage(
                                            'teeth/teeth-' + (30 + m) + '.png',
                                            30 + m
                                          )
                                        "
                                        :alt="'image teeth-' + (30 + m) + '.png'"
                                      />
                                    </li>
                                  </ul>
                                </v-col>
                              </v-row>
                            </v-container>
                          </div>

                          <!-- div lower -->
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-btn color="primary" @click="DraftStep(1)"> {{ actionCase }} </v-btn>
          <!--v-dialog v-model="dialogRefinement" persistent max-width="50%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                {{ actionCase }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                Confirm refinement submission?<br />
                Please do not forget to upload the image in the next step.
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="dialogRefinement = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" text @click="DraftStep(1)"> Confirm </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog-->

          <!--v-btn text @click="backStep(1)"> Back </!--v-btn-->
        </v-stepper-content>
        <!--upload step -->
        <v-stepper-content key="2-content" step="2">
          <v-row>
            <v-col md="4" sm="6">
              <dragdrop-upload
                v-bind:uploadName="rightProfile"
                uploadId="rightProfile"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload
            ></v-col>

            <v-col md="4" sm="6"
              ><dragdrop-upload
                v-bind:uploadName="frontalRepose"
                uploadId="frontalRepose"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload>
            </v-col>

            <v-col md="4" sm="6">
              <dragdrop-upload
                v-bind:uploadName="frontalSmiling"
                uploadId="frontalSmiling"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload
            ></v-col>

            <v-col md="4" sm="6"
              ><dragdrop-upload
                v-bind:uploadName="anteriorFrontal"
                uploadId="anteriorFrontal"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload>
            </v-col>

            <v-col md="4" sm="6">
              <dragdrop-upload
                v-bind:uploadName="buccalRight"
                uploadId="buccalRight"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload
            ></v-col>

            <v-col md="4" sm="6"
              ><dragdrop-upload
                v-bind:uploadName="buccalLeft"
                uploadId="buccalLeft"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload>
            </v-col>

            <v-col md="4" sm="6">
              <dragdrop-upload
                v-bind:uploadName="occlusalUpper"
                uploadId="occlusalUpper"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload
            ></v-col>

            <v-col md="4" sm="6">
              <dragdrop-upload
                v-bind:uploadName="occlusalLower"
                uploadId="occlusalLower"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload
            ></v-col>

            <v-col md="4" sm="6">
              <dragdrop-upload
                v-bind:uploadName="opg"
                uploadId="opg"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload
            ></v-col>

            <v-col md="4" sm="6">
              <dragdrop-upload
                v-bind:uploadName="latCeph"
                uploadId="latCeph"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload
            ></v-col>

            <v-col md="4" sm="6">
              <dragdrop-upload
                v-bind:uploadName="others"
                uploadId="others"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload
            ></v-col>

            <v-col md="4" sm="6"
              ><dragdrop-upload
                v-bind:uploadName="others2"
                uploadId="others2"
                @updateUpload="updateUpload"
                @removeUpload="removeUpload"
                v-bind:imgUrl="eForm.picOfPatientURI"
              ></dragdrop-upload>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <div class="mx-12">
                <!--v-btn color="primary" @click="toPayment(2)"> Payment </!--v-btn-->

                <v-btn text @click="backStep(1)"> Back </v-btn>
                <!-- <v-btn color="primary" @click="DraftStep(1)"> {{ actionCase }} </v-btn> -->
                <v-dialog v-model="dialogRefinement" persistent max-width="50%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on"> Confirm </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">Confirm new refinement?</v-card-title>
                    <!-- <v-card-text
                >Let Google help apps determine location. This means sending anonymous
                location data to Google, even when no apps are running.</v-card-text
              > -->
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" text @click="dialogRefinement = false">
                        Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="redirectTo('/dashboard')">
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div></v-col
            >
          </v-row>
          <v-dialog v-model="dialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                Please stand by
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-stepper-content>

        <!-- payment -->
        <v-stepper-content key="3-content" step="3">
          <payment-form
            @savePayment="savePayment"
            v-bind:doctor="doctor"
            v-bind:caseInfo="draft"
            v-if="caseLoaded"
          ></payment-form>
          <v-divider></v-divider>
          <div style="margin-top: 20px">
            <!--v-btn color="primary" @click="nextStep(0)"> Payment </!--v-btn-->

            <v-btn text @click="backStep(2)"> Back </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import dragdropdUpload from "../../library/dragdrop-upload/dragdrop-upload";
import paymentComponent from "../payment/payment";
import SystemBar from "../../library/system-bar/system-bar";
import axios from "axios";
import { nullOrUndefined, isAttachmentExists } from "../../../../plugins/libs.js";
import DoctorMenu from "../menubar/menubar";
// eslint-disable-next-line no-unused-vars
import { attachmentCfg } from "../../config/config.js";
// eslint-disable-next-line no-unused-vars
import teethAttachment from "../../library/attachment/attachment";
// eslint-disable-next-line no-unused-vars
//import { isAttachmentExists } from "../../../../plugins/libs";
export default {
  components: {
    "dragdrop-upload": dragdropdUpload,
    "payment-form": paymentComponent,
    "bfc-bar": SystemBar,
    "doctor-menu": DoctorMenu,
    "teeth-attachment": teethAttachment,
  },
  data: () => {
    return {
      dialogRefinement: false,
      uploading: false,
      showAttachment: false,
      teethNo: 0,
      dialog: false,
      caseId: "",
      caseNo: "",
      sequence: "",
      custId: process.env.VUE_APP_OGF_CUST_ID,
      doctorId: "",
      memberId: "",
      clinicId: "",
      url: process.env.VUE_APP_LOYALTY_API_URI,
      actionCase: "Next",
      refCaseNo: "",
      rightProfile: "Right Profile",
      frontalRepose: "Frontal Repose",
      frontalSmiling: "Frontal Smiling",
      anteriorFrontal: "Anterior Frontal",
      buccalRight: "Buccal Right",
      buccalLeft: "Buccal Left",
      occlusalUpper: "Occlusal Upper",
      occlusalLower: "Occlusal Lower",
      opg: "OPG",
      latCeph: "Lat Ceph",
      others: "Others",
      others2: "Others 2",
      attachmentData: [],
      caseFormDental: [
        {
          subject: "Dental Condition (Please indicate the tooth for each condition)",
          key: "DentalCondition",
          values: [
            {
              key: "DeciduousTeeth",
              text: "Deciduous teeth",
              childText: true,
            },
            {
              key: "Supernumerary",
              text: "Supernumerary",
              childText: true,
            },
            {
              key: "MissingTeeth",
              text: "Missing teeth",
              childText: true,
            },
            {
              key: "BuriedTeeth",
              text: "Buried teeth",
              childText: true,
            },
            {
              key: "ImpactedTeeth",
              text: "Impacted teeth",
              childText: true,
            },
          ],
        },
        {
          subject: "Dental Restoration (Please indicate the tooth for each restoration)",
          key: "DentalRestoration",
          values: [
            {
              key: "AmalgamFilling",
              text: "Amalgam filling",
              childText: true,
            },
            {
              key: "ToothColouredFillingCompositeGLC",
              text: "Tooth coloured filling - composite/GLC",
              childText: true,
            },
            {
              key: "InlayAndOnlayMetalCeramicOrZirconia",
              text: "Inlay and onlay - metal, ceramic, or Zirconia",
              childText: true,
            },
            {
              key: "CrownMetalCeramicZirconia",
              text: "Crown - metal, ceramic, Zirconia",
              childText: true,
            },
            {
              key: "RCTTeeth",
              text: "RCT teeth",
              childText: true,
            },
          ],
        },
      ],
      caseStructure: {
        workOnlyOn: ["Posterior", "Antierior"],
        toCollectMidline: {
          radio: ["Yes", "No"],
          childs: [
            {
              title: "By Shifting Maxillary",
              key: "ByShiftingMaxillary",
              radio: ["To Patient's Left", "To Patient's Right"],
            },
            {
              title: "By Shifting Mandibular",
              key: "ByShiftingMandibular",
              radio: ["To Patient's Left", "To Patient's Right"],
            },
          ],
        },
        radioBoxForm: [
          {
            key: "toCorrectOverBite",
            title: "To Correct OverBite",
            values: ["Yes", "No"],
          },
          { key: "toCorrectOverJet", title: "To Correct OverJet", values: ["Yes", "No"] },
          {
            key: "idealOverBiteOverJetis",
            title: "Ideal OverBite/OverJet is",
            values: ["2mm", "3mm", "4mm"],
          },
        ],
        //
        ToCorrectSpacing: [
          {
            key: "ToCorrectSpacing",
            title: "To Correct Spacing",
            values: ["Yes", "No"],
            childs: [
              {
                key: "TypeofSpaceClosure",
                title: "Type of Space Closure",
                values: [
                  "Type A (close incisal spaces and shift back anterior segment)",
                  "Type B (shift back anterior, shift forward posterior segment)",
                  "Type C (shift forward posterior segment only)",
                  "Others (Please put details in the Instructions/Notes box below)",
                ],
              },
            ],
          },
        ],
        //
        ToCorrectCrowding: [
          {
            key: "ToCorrectCrowding",
            title: "To Correct Crowding",
            values: ["Yes", "No"],
            childs: [
              {
                key: "RelieveCrowdingBy",
                title: "Relieve crowding by",
                values: ["Stripping", "Expansion", "Flaring of Incisors"],
              },
            ],
          },
        ],
        //
        // text
        ModifyTorque: [
          {
            key: "ModifyTorque",
            title: "Modify Torque",
            values: ["Yes", "No"],
            childs: [
              {
                key: "WhichTeethandAmount",
                title: "Which Teeth and Amount?",
              },
            ],
          },
        ],
        // text
        //
        ApplyRotation: [
          {
            key: "ApplyRotation",
            title: "Apply Rotation",
            values: ["Yes", "No"],
            childs: [
              {
                key: "WhichTeethandAmount",
                title: "Which Teeth and Amount?",
              },
            ],
          },
        ],
      },

      doctor: {},
      eForm: {
        patient_name: "",
        firstname: "",
        lastname: "",
        clinicName: "",
        mobile: "",
        gender: "",
        birthdate: "",
        race: "",
        DentalCondition: [],
        attachmentData: [],
        caseFormDental: {
          DentalCondition: {
            DeciduousTeeth: "",
            Supernumerary: "",
            MissingTeeth: "",
            BuriedTeeth: "",
            ImpactedTeeth: "",
          },
          DentalRestoration: {
            AmalgamFilling: "",
            ToothColouredFillingCompositeGLC: "",
            InlayAndOnlayMetalCeramicOrZirconia: "",
            CrownMetalCeramicZirconia: "",
          },
        },
        DentalRestoration: [],
        TreatWhichArch: "",
        teeth2extract_cbx: [],
        workOnlyOn: [],
        ToCollectMidline: "",
        ByShiftingMaxillary: "",
        ByShiftingMandibular: "",
        toCorrectOverBite: "No",
        toCorrectOverJet: "No",
        ToCorrectSpacing: {
          value: "No",
          TypeofSpaceClosure: "",
          TypeofSpaceClosure_other: "",
        },
        //
        ToCorrectCrowding: {
          value: "No",
          RelieveCrowdingBy: [],
          RelieveCrowdingBy_other: "",
          RelieveCrowdingByStripping: "",
          RelieveCrowdingByStrippingTeeth: [],
          NoteonStrippingOfMaxilla: "",
          NoteonStrippingOfMandible: "",
          bfc_li_RelieveCrowdingByStripping_cbx: [],
        },

        // text
        ModifyTorque: {
          value: "No",
          WhichTeethandAmount: "",
        },
        // text
        ApplyRotation: {
          value: "No",
          WhichTeethandAmount: "",
        },
        //
        attachment: "PleasePropose",
        attachment_selected: "No",
        attachment_cbx: [],
        picOfPatient: {
          rightProfile: "",
          frontalRepose: "",
          frontalSmiling: "",
          anteriorFrontal: "",
          buccalRight: "",
          buccalLeft: "",
          occlusalUpper: "",
          occlusalLower: "",
          opg: "",
          latCeph: "",
          others: "",
          others2: "",
        },
        picOfPatientURI: {},
      },
      birthdate: "",
      e1: 1,
      nSteps: 3,
      teeth2extract_cbx: [],
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/doctor/dashboard",
        },
        {
          text: "Refinement",
          disabled: true,
          href: "new-case",
        },
      ],
      caseLoaded: false,
      draft: {},
      domain: process.env.VUE_APP_DOMAIN,
      package: {},
      influencer: 0,
    };
  },
  created() {
    const omiseExists = document.getElementById("omiseScript");
    if (!omiseExists) {
      const omiseScript = document.createElement("script");
      omiseScript.setAttribute("src", "https://cdn.omise.co/omise.js");
      omiseScript.setAttribute("id", "omiseScript");
      document.head.appendChild(omiseScript);
    }
  },
  computed: {
    ...mapGetters({
      getAuth: "BFC/getAuth",
      getDoctorInfo: "BFC/getDoctorInfo",
      getDraft: "BFC/getDraft",
    }),
    info() {
      return this.getDoctorInfo;
    },
    caseData() {
      return this.eForm;
    },
  },
  mounted() {
    console.log(this.$route.params.caseNo);
    //this.caseLoaded = false;
    //this.OmiseCard = OmiseCard;
    // eslint-disable-next-line no-undef
    // OmiseCard.configure({
    //   publicKey: this.omisePublicKey,
    //   image: "https://cdn.omise.co/assets/dashboard/images/omise-logo.png",
    //   amount: this.price / this.division + "00",
    //   //submitFormTarget: "#form",
    //   frameDescription: this.description + "( Case : " + this.caseNo + " )",
    //   // onCreateTokenSuccess: (nonce) => {
    //   //   console.log(nonce);
    //   //   //if (typeof nonce.id !== "undefined") {
    //   //   //
    //   //   this.omiseResponse = nonce;
    //   //   if (nonce.id.startWith("tokn_")) {
    //   //     this.savePayment();
    //   //   } else {
    //   //     alert("Payment error!");
    //   //   }
    //   //   //}
    //   // },
    // });
    //this.doctor = { doctor: this.$cookies.get("Profile").doctor };
    this.doctor = { doctor: JSON.parse(localStorage.getItem("Profile")).doctor };
    this.influencer = this.doctor.doctor[0].influencer;

    //console.log("mounted");
    //console.log(this.doctor);
    if (this.$route.params.sequence < 3) {
      this.nSteps = 2;
    }
    this.init();
  },
  methods: {
    ...mapActions({
      signout: "BFC/Logout",
      createDraft: "BFC/createDraftCase",
      updateDraft: "BFC/updateDraftCase",
      removeImage: "BFC/removeDoctorDraftImage",
      addNewCase: "BFC/addNewCase",
      updateCasePatientImg: "BFC/updateCasePatientImg",
    }),
    closeAttachment() {
      this.showAttachment = false;
    },
    async DraftStep(n) {
      // check require
      let apiUri = "";
      //let draft;
      //console.log(this.caseNo);
      let payload = {};
      //if (this.caseNo === null) {
      apiUri = "/api/bfc/v1/doctor/createDraft";
      this.eForm.attachmentData = this.attachmentData;
      this.eForm.patient_name = this.eForm.firstname + " " + this.eForm.lastname;
      payload = {
        caseDetail: this.eForm,
        package: this.package,
        caseId: this.caseId,
        caseNo: this.$route.params.caseNo,
        sequence: this.$route.params.sequence,
        runNo: this.runNo,
        refCaseNo: this.refCaseNo,
        //clinicId: this.clinicId,
        doctorId: this.doctorId,
        custId: this.custId,
        caseStatus: {
          stateStatus: this.$route.params.sequence < 3 ? "Submitted" : "Draft",
        },
        stepUpload: [],
        labUpload: [],
      };
      // } else {
      //   apiUri = "/api/bfc/v1/doctor/updateDraft";
      //   payload = {
      //     caseDetail: this.eForm,
      //     //clinicId: this.clinicId,
      //     caseNo: this.$route.params.caseNo + "-" + parseInt(this.$route.params.sequence),
      //     sequence: parseInt(this.$route.params.sequence),
      //     doctorId: this.doctorId,
      //     custId: this.custId,
      //     //caseStatus: { stateStatus: "Draft" },
      //   };
      // }

      try {
        //let payload

        //this.eForm.patient_name = this.eForm.firstname + " " + this.eForm.lastname;
        // payload = {
        //   caseDetail: this.eForm,
        //   //clinicId: this.clinicId,
        //   doctorId: this.doctorId,
        //   custId: this.custId,
        //   //caseStatus: { stateStatus: "Draft" },
        // };
        await axios
          .post(
            this.url + apiUri,
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              //if (this.caseNo == null) {
              //this.caseNo = response.data._doc.caseNo;
              //this.caseId = response.data._doc._id;
              //}
              this.dialogRefinement = false;

              this.draft = {
                caseNo: response.data._doc.caseNo,
                caseId: response.data._doc._id,
                //clinicId: this.clinicId,
                doctorId: this.doctorId,
                custId: this.custId,
                caseDetail: this.eForm,
              };

              if (this.caseNo === null) {
                this.createDraft(this.draft);
              } else {
                this.updateDraft(this.draft);
              }
              this.caseId = this.draft.caseId;
              this.caseNo = this.draft.caseNo;

              this.actionCase = "Update Case";

              if (n === this.nSteps) {
                this.e1 = 1;
              } else {
                this.e1 = n + 1;
              }
            } else {
              // show error
              console.log("error response");
            }
          });
        console.log("nextStep upload");
        //this.nextStep(1);
      } catch (e) {
        //
        console.log(e);
      }
    },
    async toPayment(n) {
      this.caseLoaded = true;
      this.dialog = true;
      /*
      let payload = {
        caseId: this.caseId,
        caseDetail: { picOfPatient: this.eForm.picOfPatient },
      };

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/updateDraftImage",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);

            if (response.data.statusCode == 0) {
              if (n === this.nSteps) {
                this.e1 = 1;
              } else {
                this.e1 = n + 1;
              }
            } else {
              // show error
              alert("Error");
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
      */
      if (n === this.nSteps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
      this.dialog = false;
    },
    async savePayment(payment) {
      let _stateStatus = "Draft";
      let _paymentAgent = "Omise";
      let _paymentStatus = "Pending";
      let return_uri = this.domain + "/doctor/payment-status/" + payment.caseNo;
      if (this.doctor.doctor[0].vip == 1) {
        _stateStatus = "Submitted";
        _paymentAgent = "VIP";
        _paymentStatus = "Paid";
      }
      let payload = {
        caseId: this.caseId,
        custId: this.custId,
        doctorId: this.doctorId,
        memberId: this.memberId,
        caseNo: payment.caseNo,
        case: {
          caseStatus: {
            stateStatus: _stateStatus,
          },
          package: {
            text: "",
            value: "",
            price: 0,
            packageStatus: "Waiting",
          },
        },

        casePayment: {
          caseId: this.caseId,
          memberId: this.memberId,
          doctorId: this.doctorId,
          vip: this.doctor.doctor[0].vip,
          return_uri: return_uri,
          omise: payment.omise,
          caseNo: payment.caseNo,

          payment: {
            payment_type: payment.payment_type,
            type: payment.paymentType,
            description: payment.description,
            amount: payment.amount,
            price: payment.price,
            discount: payment.discount,
            caseNo: payment.caseNo,

            paymentDate: new Date().toISOString(),
            paymentAgent: _paymentAgent,
            paymentStatus: _paymentStatus,
            influencer: this.influencer,
          },

          description: payment.description,
          paymentStatus: "Waiting",
        },
      };
      // eslint-disable-next-line no-unused-vars
      let paymentUrl = "/api/bfc/v1/doctor/saveNewCase";
      if (this.doctor.doctor[0].vip == 1) {
        // paymentUrl = "/api/bfc/v1/payment/vipPayment";
      }
      try {
        await axios
          .post(
            this.url + paymentUrl,
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log(response.data.data);
            if (response.data.statusCode == 0) {
              let newCase;
              //newCase.push(response.data.data);
              //console.log(typeof newCase[0]);
              newCase = response.data.data.map((item) => {
                //console.log(item);
                return {
                  caseNo: item.caseNo,
                  patient: nullOrUndefined(item.caseDetail.patient_name),
                  process: "",
                  aligner_wear_log: "",
                  gender: nullOrUndefined(item.caseDetail.gender),
                  race: nullOrUndefined(item.caseDetail.race),
                  birthdate: nullOrUndefined(item.caseDetail.birthdate),
                  mobile: nullOrUndefined(item.caseDetail.mobile),
                  actions: item._id,
                  caseStatus: item.caseStatus,
                };
              });
              console.log(newCase);
              //this.addNewCase(newCase[0]);
              //this.$router.push("/doctor/dashboard");
              console.log("response.data.data");
              console.log(response.data);

              console.log(this.doctor.doctor[0].vip);
              /*
              if (this.doctor.doctor[0].vip == 0 && this.influencer != 1) {
                if (typeof response.data.omise !== "undefined") {
                  //
                  window.location.href = response.data.omise.authorize_uri;
                } else {
                  alert("Payment Error!");
                }
              } else {
                this.$router.push("/doctor/payment-status/" + payment.caseNo);
              }
              */
              if (
                (this.doctor.doctor[0].vip != 1 ||
                  this.doctor.doctor[0].vip == null) &&
                this.influencer != 1
              ) {
                if ( response.data.payment_type != "promptpay") {
                  //
                  window.location.href = response.data.omiseData.authorize_uri;
                } else {
                  //alert("Payment Error!");
                 
                    if(response.data.payment_type == "promptpay"){
                      this.$router.push("/doctor/payment-status/" + payment.caseNo);
                    }
                  
                }
              } else {
                this.$router.push("/doctor/payment-status/" + payment.caseNo);
              }
            } else {
              // show error
              console.log("error response");
            }
            
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
    nextStep(n) {
      if (n === this.nSteps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    backStep(n) {
      if (n === 0) {
        this.e1 = 1;
      } else {
        this.e1 = n;
      }
    },
    redirectTo(path) {
      this.$router.push("/doctor" + path);
    },
    async init() {
      //console.log(this.info);
      //this.doctor = this.info;
      //console.log(this.doctor);
      if (this.doctor.length == 0) {
        this.logout();
      }
      console.log(this.doctor.doctor[0]);
      this.eForm.doctor =
        this.doctor.doctor[0].firstname + " " + this.doctor.doctor[0].lastname;
      this.custId = this.doctor.doctor[0].custId;
      this.doctorId = this.doctor.doctor[0]._id;
      //this.clinicId = this.doctor.clinic[0]._id;
      this.memberId = this.doctor.doctor[0].memberId;
      this.eForm.clinicName = this.doctor.doctor[0].clinic.clinicName;

      let payload = {
        caseNo: this.$route.params.caseNo,
        custId: this.custId,
        selectField:
          "caseNo _id custId doctorId refCaseNo runNo caseDetail.patient_name caseDetail.firstname caseDetail.lastname package",
      };

      await axios
        .post(
          this.url + "/api/bfc/v1/case/caseInfo",
          payload, // need to change
          {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (response) => {
          console.log("caseInfo");
          console.log(response.data.data);
          let r = response.data.data[0];
          // this.eForm.firstname = response.data.data[0].caseDetail.firstname;
          // this.eForm.lastname = response.data.data[0].caseDetail.lastname;
          //r.package = parseInt(this.$route.params.sequence) === 3 ? {} : r.package;
          this.eForm.firstname = r.caseDetail.firstname;
          this.eForm.lastname = r.caseDetail.lastname;
          //this.eForm.clinicName = r.caseDetail.clinicName;
          this.caseId = r._id;
          this.runNo = r.runNo;
          this.package = parseInt(this.$route.params.sequence) === 3 ? {} : r.package;
          this.refCaseNo = r.refCaseNo;
        });

      //    this.doctor
    },
    logout() {
      this.signout();
      this.$router.push("/doctor");
    },
    toCollectMidlineHandle() {
      //console.log(this.eForm.ToCollectMidline);
      if (this.eForm.ToCollectMidline == "No") {
        this.eForm.ByShiftingMaxillary = "";
        this.eForm.ByShiftingMandibular = "";
      }
    },
    toCollectSpacingHandle() {
      //console.log(this.eForm.ToCollectMidline);
      if (this.eForm.ToCorrectSpacing.value == "No") {
        this.eForm.TypeofSpaceClosure = "";
        this.eForm.TypeofSpaceClosure_other = "";
      }
    },
    ModifyTorqueHandle() {
      //console.log(this.eForm.ModifyTorque.value);
      if (this.eForm.ModifyTorque.value == "No") {
        this.eForm.ModifyTorque.WhichTeethandAmount = "";
      }
    },
    ApplyRotationHandle() {
      //console.log(this.eForm.ModifyTorque.value);
      if (this.eForm.ApplyRotation.value == "No") {
        this.eForm.ApplyRotation.WhichTeethandAmount = "";
      }
    },
    async updateUpload(val) {
      console.log(val);
      //this.eForm.picOfPatient[val.id] = val.image;
      this.uploading = true;

      //this.createDraft(this.eForm);
      //console.log(this.getDraft);
      let payload = {
        picOfPatientKey: val.id,
        picOfPatientData: val.image,
        custId: this.custId,
        caseId: this.caseId,
      };
      console.log(payload);
      //this.eForm.picOfPatient[val.id] = val.image;
      //this.updateCasePatientImg(payload);
      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/updateOneCaseFormImage",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            //return response.data;
            console.log(response);
            this.eForm.picOfPatientURI[val.id] = response.data.data.savedUrl;
          });
      } catch (e) {
        //
        console.log(e);
        // return {
        //   statusCode: 500,
        //   statusMessage: "Error",
        //   data: e,
        // };
      }
      this.uploading = false;
    },
    async removeUpload(val) {
      this.eForm.picOfPatient[val.id] = "";
      this.removeImage(val);
      console.log(this.getDraft);
      //this.remove(this.eForm);
      /********************/
      this.eForm.picOfPatient[val.id] = "";
      this.eForm.picOfPatientURI[val.id] = "";
      //this.removeImage(val);
      //console.log(this.getDraft);
      //this.remove(this.eForm);
      // let removeImage = {};
      // removeImage["caseDetail"] = {};
      // removeImage["caseDetail"]["picOfPatient"] = {};
      // removeImage["caseDetail"]["picOfPatientURI"] = {};
      // removeImage["caseDetail"]["picOfPatient"][val.id] = "";
      // removeImage["caseDetail"]["picOfPatientURI"][val.id] = "";
      console.log("remove Upload");

      try {
        let payload = {
          caseNo: this.caseNo,
          custId: this.custId,
          caseId: this.caseId,
          doctorId: this.doctorId,
          // removeImage: removeImage,
          picOfPatientKey: val.id,
        };
        console.log(payload);
        //console.log(selectPackage);
        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/removePicOfPatient",
            // need to change
            payload,
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log(response);
            if (response.data.statusCode == 0) {
              alert("Saved!");
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
    selectAttachment(i) {
      //console.log(i);
      this.showAttachment = true;
      this.teethNo = i;
      //console.log(this.showAttachment);
    },
    selectAttachmentTeeth(attachmentInfo) {
      //console.log(attachmentInfo);
      //console.log(attachmentInfo);
      //let prepareObj = {};
      //this.$set(this.attachmentData, attachmentInfo.teethNo, attachmentInfo);
      //this.attachment_cbx.push({attachmentInfo.teethNo: attachmentInfo};
      //this.attachmentData;
      //console.log(typeof this.attachmentData);
      if (!isAttachmentExists(this.attachmentData, attachmentInfo.teethNo)) {
        //
        this.attachmentData.push(attachmentInfo);
      } else {
        this.attachmentData = this.attachmentData.filter((item) => {
          return item.teethNo !== attachmentInfo.teethNo;
        });
        this.attachmentData.push(attachmentInfo);
      }

      //console.log(this.attachmentData);
    },
    displayToothImage(teeth, teethNo) {
      //console.log(teethNo);
      //console.log(this.attachmentData);
      //console.log(require(`../../assets/${teeth}`));
      let selectTeeth = require(`../../assets/${teeth}`);
      if (isAttachmentExists(this.attachmentData, teethNo)) {
        let selectTeethPath = this.attachmentData
          .filter((item) => {
            return item.teethNo == teethNo;
          })
          .map((item) => {
            return item.path + item.imgName;
          });
        selectTeeth = require(`../../assets/${selectTeethPath}`);
      }
      //console.log(selectTeeth);
      return selectTeeth;
    },
  },
  watch: {
    caseData() {
      console.log();
      console.log("--------------------");
      console.log(this.caseData);
    },
  },
};
</script>
<style scope type="css">
.bfc_li_teeth2extract,
.bfc_li_teeth2extract li {
  font-size: 0.97em;
  display: inline;
}

.bfc_li_teeth2extract_cbx {
  margin-left: 5.4px;
}

.bfc_li_RelieveCrowdingByStripping {
  display: inline;
  margin-left: 175px;
}

.bfc_li_RelieveCrowdingByStripping.mandible {
  display: inline;
  margin-left: 210px;
}

.bfc_li_RelieveCrowdingByStripping li {
  font-size: 0.97em;
  display: inline;
}

.bfc_li_RelieveCrowdingByStripping_cbx {
  /*padding-left: 20px;*/
}
.padding14 {
  padding-left: 14px;
}
.padding16 {
  padding-left: 16px;
}

.padding19 {
  padding-left: 19px;
}

.padding18 {
  padding-left: 19px;
}

.padding22 {
  padding-left: 22px;
}

.padding24 {
  padding-left: 24px;
}

.padding26 {
  padding-left: 26px;
}

.padding30 {
  padding-left: 30px;
}

/************/
.attachment {
  position: relative !important;
  display: block !important;
  width: 220px !important;
  height: auto;
  float: left;
}
.attachment ul {
  position: relative;
  /*display: inline-block;*/
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  list-style-position: outside;
  float: left;
  margin-block-start: em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.attachment li {
  font-size: 0.97em !important;
  padding-left: 6px;
  cursor: pointer;
}
.attachment li img:hover {
  border: 2px solid;

  border-color: #fb8c00;
}
.attachment li:nth-child(2n-1) {
  background-color: #dfdfdf;
  width: 100%;
}

.attachment li img {
  max-width: 62px;
}

.quadrant {
  position: relative !important;
  /*display: inline-block !important;*/
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  list-style-position: outside;
  float: left;
}
.quadrant li {
  font-size: 0.97em !important;
  text-align: center;
  margin-left: -32px;
  margin-right: 32px;
}
.uploadForm {
  display: block;
  width: 100% !important;
}

.teeth2extract td {
  width: 2rem;
  text-align: center;
}
.teeth2extract td img {
  margin-top: 20px;
  margin-bottom: 20px;
}

.teeth2extract td input[type="checkbox"] {
  text-align: center;
}
</style>
